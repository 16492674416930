import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M5325 7950 c-238 -18 -437 -61 -675 -143 -175 -61 -311 -118 -385
-161 -27 -15 -72 -39 -100 -52 -55 -26 -222 -130 -252 -155 -10 -9 -44 -34
-75 -56 -32 -22 -86 -64 -120 -94 -70 -60 -302 -290 -335 -331 -12 -14 -42
-51 -67 -82 -25 -31 -71 -95 -102 -143 -31 -49 -69 -106 -84 -128 -28 -41 -87
-155 -158 -305 -32 -69 -93 -241 -162 -465 -29 -94 -78 -501 -66 -550 7 -28
42 -35 188 -35 96 0 135 -9 109 -24 -15 -10 27 -86 48 -86 15 0 21 11 27 47 9
46 11 48 49 54 22 4 187 10 367 14 388 8 361 17 372 -116 18 -227 54 -356 166
-604 81 -179 288 -432 455 -555 127 -94 244 -166 314 -193 262 -102 394 -137
521 -137 41 0 49 3 54 23 8 29 8 196 0 235 -7 35 -30 46 -119 58 -69 9 -239
66 -340 113 -178 83 -364 236 -483 396 -112 152 -212 400 -237 593 -27 199
-14 354 45 537 34 106 138 319 175 359 12 13 32 39 43 58 19 31 138 154 202
208 21 19 65 48 175 118 33 21 188 94 220 104 17 5 91 24 165 43 86 22 139 40
147 51 9 12 13 58 13 145 0 157 1 156 -106 140 -43 -6 -79 -9 -82 -6 -3 2 1
18 8 36 11 25 11 40 0 83 -21 85 -50 156 -68 165 -22 11 -130 -4 -168 -24 -16
-8 -35 -15 -42 -15 -7 0 -19 -15 -27 -32 l-14 -33 -15 37 c-25 60 -22 65 41
73 63 8 170 52 192 79 13 15 10 30 -19 122 -19 58 -39 109 -46 114 -6 6 -37
16 -70 23 l-59 14 55 16 c94 28 300 65 402 72 115 8 444 -10 543 -30 39 -8
105 -19 148 -25 140 -21 254 -59 467 -157 47 -22 101 -46 120 -53 30 -12 57
-26 147 -78 41 -24 224 -152 253 -177 19 -17 53 -44 75 -60 49 -36 265 -252
265 -266 0 -5 18 -29 40 -53 61 -66 141 -189 129 -201 -15 -15 -10 -25 11 -25
12 0 35 -7 52 -16 22 -11 45 -14 75 -10 30 4 54 1 79 -11 27 -13 39 -15 53 -6
10 6 55 15 100 19 53 4 89 13 103 23 l20 17 -32 64 c-18 35 -40 75 -49 89 -10
14 -39 60 -66 101 -48 73 -153 215 -205 275 -39 46 -250 256 -300 299 -129
111 -439 326 -471 326 -6 0 -23 8 -37 19 -33 23 -183 93 -237 111 -22 7 -44
16 -50 20 -16 12 -151 59 -275 96 -136 40 -322 80 -437 93 -122 15 -461 21
-598 11z m-277 -542 c19 -23 62 -141 62 -169 0 -24 -7 -30 -53 -47 -29 -11
-68 -22 -87 -24 -31 -3 -36 1 -55 37 -16 30 -28 41 -49 43 -36 4 -56 -13 -56
-50 0 -54 85 -287 110 -303 17 -11 67 14 73 36 3 10 1 37 -4 60 -5 23 -7 44
-4 47 8 9 138 51 157 52 19 0 34 -29 68 -128 20 -58 20 -65 6 -87 -28 -43 -79
-72 -192 -109 -168 -55 -245 -45 -332 42 -98 98 -151 308 -106 418 29 71 97
127 197 164 66 24 85 27 178 29 51 1 80 -3 87 -11z m-446 -97 c-51 -64 -66
-121 -60 -224 12 -185 106 -332 241 -376 20 -7 37 -16 37 -19 0 -9 -129 -88
-157 -96 -19 -6 -29 3 -69 63 -26 39 -50 72 -53 72 -3 1 -17 3 -30 4 -27 3
-38 15 -121 138 -48 70 -60 96 -60 127 0 30 -9 49 -45 93 -25 31 -43 62 -40
69 6 14 74 61 141 98 48 26 223 97 242 99 7 1 -5 -21 -26 -48z m-338 -238 c25
-37 28 -46 18 -67 -11 -26 -13 -22 158 -266 32 -44 39 -50 71 -50 19 0 34 -11
53 -38 35 -50 33 -64 -11 -98 -82 -63 -198 -134 -218 -134 -15 0 -31 14 -50
41 -23 36 -25 45 -15 64 11 20 5 33 -60 126 -115 166 -139 192 -166 185 -19
-5 -29 1 -54 35 -16 23 -30 50 -30 59 0 27 231 193 260 188 8 -2 27 -22 44
-45z m-299 -223 c31 -41 53 -60 68 -60 33 0 39 -6 121 -124 68 -97 76 -113 70
-142 -5 -28 1 -42 37 -94 24 -35 52 -63 66 -66 12 -4 23 -9 23 -12 0 -3 -26
-37 -58 -76 -31 -39 -71 -91 -87 -116 -16 -25 -37 -54 -46 -65 -26 -31 -92
-158 -144 -280 -77 -179 -50 -159 -222 -166 -240 -10 -520 -10 -547 1 -22 8
-26 16 -26 52 0 48 25 142 77 293 37 105 167 377 206 429 13 17 33 48 45 70
40 68 155 208 257 313 55 57 104 103 107 103 4 0 28 -27 53 -60z"/>
<path d="M5607 7510 c-114 -29 -172 -85 -211 -204 -25 -73 -28 -95 -24 -182 3
-81 8 -109 30 -155 26 -57 36 -69 96 -122 l32 -27 0 -129 c0 -156 4 -163 100
-181 161 -30 324 -92 450 -171 30 -18 67 -41 81 -49 67 -38 221 -180 281 -259
24 -31 51 -65 61 -76 28 -30 130 -240 160 -326 45 -130 71 -328 62 -454 -10
-125 -46 -305 -75 -370 -51 -117 -115 -245 -121 -245 -4 0 -10 -9 -14 -20 -6
-20 -71 -104 -135 -174 -44 -48 -182 -164 -235 -196 -72 -45 -79 -49 -145 -81
-36 -17 -77 -37 -91 -44 -45 -24 -182 -66 -263 -80 -43 -8 -85 -20 -92 -26
-20 -17 -20 -271 0 -288 41 -34 307 29 544 128 192 80 447 274 584 444 70 86
193 285 233 376 78 177 135 418 135 573 0 92 -17 88 349 88 211 0 329 -4 351
-11 l32 -12 -6 -111 c-5 -103 -16 -209 -30 -281 -3 -16 -10 -61 -17 -100 -31
-191 -145 -482 -262 -665 -102 -161 -110 -173 -176 -255 -169 -212 -397 -410
-642 -558 -104 -63 -297 -162 -339 -175 -19 -6 -64 -22 -100 -35 -81 -31 -317
-92 -385 -101 -27 -3 -99 -12 -160 -21 -127 -17 -407 -20 -522 -5 -283 37
-418 70 -683 166 -155 56 -443 222 -552 320 -15 13 -32 24 -37 24 -10 0 -199
174 -266 245 -203 213 -327 412 -461 735 -32 75 -59 100 -109 100 -41 0 -45 3
-61 36 -14 29 -22 35 -43 32 -22 -3 -26 -8 -26 -35 0 -17 -3 -34 -7 -38 -3 -4
-52 -9 -107 -12 -119 -7 -116 -3 -75 -129 83 -258 256 -573 437 -799 95 -118
376 -400 452 -453 33 -23 87 -62 120 -86 33 -25 92 -63 130 -86 39 -23 95 -56
125 -75 78 -48 293 -145 445 -200 143 -52 356 -103 576 -136 211 -32 609 -32
789 -1 135 24 374 76 430 94 168 53 460 175 535 223 28 17 84 51 125 75 74 42
162 102 189 128 7 7 37 28 65 46 83 53 346 301 426 402 8 11 41 50 73 87 153
182 355 552 429 787 45 143 99 372 118 499 33 218 23 712 -14 750 -10 9 -39
13 -97 12 -104 -1 -142 6 -135 25 3 8 1 14 -3 14 -5 0 -11 -4 -13 -9 -1 -5
-35 -13 -75 -16 l-72 -8 -22 37 c-40 68 -71 69 -86 3 -3 -16 -11 -31 -17 -33
-16 -6 -609 -12 -624 -6 -16 6 -44 60 -67 132 -77 237 -312 549 -546 725 -55
41 -102 75 -104 75 -3 0 -37 20 -77 45 -40 25 -76 45 -80 45 -3 0 -39 15 -80
33 -40 19 -102 44 -138 57 -36 13 -69 26 -73 30 -5 4 11 22 36 39 88 60 125
126 146 254 23 140 -12 270 -91 343 -74 68 -240 109 -341 84z m162 -31 c87
-14 121 -30 165 -80 61 -67 76 -114 76 -239 0 -133 -20 -197 -81 -259 -55 -56
-95 -71 -188 -71 -208 1 -308 68 -336 225 -14 80 -7 193 17 265 25 80 92 138
178 156 71 16 90 16 169 3z"/>
<path d="M5697 7332 c-29 -6 -39 -49 -44 -198 l-6 -141 31 -11 c48 -17 72 6
72 71 0 28 5 88 12 134 17 114 -3 159 -65 145z"/>
<path d="M6390 7366 c-8 -6 -28 -35 -43 -64 -23 -42 -27 -60 -23 -95 5 -37 0
-54 -51 -152 -50 -95 -62 -111 -87 -115 -23 -4 -35 -17 -62 -71 -19 -37 -32
-73 -29 -81 6 -14 62 -51 165 -106 36 -19 69 -39 75 -43 20 -19 46 -7 71 30
14 22 32 42 39 45 17 6 41 -44 49 -107 5 -28 16 -55 28 -67 29 -27 187 -102
206 -98 11 2 54 75 112 189 105 205 114 219 142 219 14 0 28 18 50 66 43 93
44 92 -137 190 -44 24 -87 44 -94 44 -8 0 -28 -12 -44 -27 -27 -26 -29 -27
-42 -10 -7 10 -16 38 -20 63 -6 41 -11 48 -58 78 -29 18 -93 54 -142 79 -72
36 -94 43 -105 33z m110 -67 c30 -16 77 -43 105 -61 58 -38 58 -39 76 -238 6
-74 15 -139 19 -144 5 -4 22 21 39 56 26 56 29 69 19 94 -9 25 -7 36 11 73 20
38 25 42 49 36 29 -7 151 -74 192 -105 l25 -19 -25 -48 c-17 -32 -33 -49 -50
-53 -20 -5 -40 -36 -110 -171 -127 -248 -125 -244 -146 -241 -39 7 -166 87
-180 114 -8 15 -14 51 -14 80 0 77 -23 308 -30 308 -9 0 -61 -95 -77 -139 -8
-24 -9 -38 -1 -51 14 -22 4 -62 -25 -96 l-23 -26 -45 22 c-24 12 -77 43 -117
67 -79 49 -79 51 -42 117 10 19 27 33 44 36 14 3 32 16 40 30 7 14 41 75 75
137 59 109 71 145 51 158 -17 10 -11 37 16 72 29 38 34 38 124 -8z"/>
<path d="M5364 6479 c-87 -6 -113 -13 -185 -45 -117 -53 -204 -120 -272 -208
-44 -58 -47 -89 -11 -112 23 -16 28 -16 60 -1 19 9 34 21 34 26 0 5 26 36 58
69 63 65 164 122 261 149 73 21 250 23 316 4 82 -23 201 -89 247 -138 24 -25
60 -64 80 -85 32 -34 42 -39 65 -33 31 7 43 21 43 50 0 39 -40 88 -150 184
-44 38 -183 109 -230 116 -19 4 -53 10 -75 15 -61 14 -130 16 -241 9z"/>
<path d="M7948 6313 c-7 -10 -33 -31 -57 -48 -25 -16 -57 -41 -71 -55 -15 -14
-41 -36 -58 -49 -39 -29 -33 -42 15 -36 37 5 46 12 128 110 22 26 51 58 65 71
20 19 21 24 8 24 -9 0 -23 -8 -30 -17z"/>
<path d="M5327 6269 c-122 -29 -292 -170 -321 -266 -5 -17 -1 -29 18 -48 21
-21 29 -23 48 -14 13 5 36 30 52 55 40 63 95 106 178 140 63 25 85 29 173 29
93 0 105 -2 173 -34 72 -34 121 -77 172 -153 31 -46 46 -53 77 -33 34 23 38
41 18 86 -41 90 -193 201 -320 235 -56 15 -210 17 -268 3z"/>
<path d="M7535 6090 c-11 -5 -14 -9 -7 -9 18 -1 77 -29 127 -60 32 -20 56 -25
122 -28 46 -2 83 -1 83 2 0 2 -30 19 -67 37 -38 17 -70 35 -73 38 -21 28 -138
40 -185 20z"/>
<path d="M5323 6020 c-101 -45 -213 -145 -213 -191 0 -51 78 -67 107 -22 26
39 72 80 118 105 31 17 58 22 125 22 74 1 92 -3 137 -26 29 -16 74 -50 100
-78 51 -54 66 -59 102 -34 30 21 22 52 -27 110 -45 54 -89 84 -165 112 -76 29
-221 30 -284 2z"/>
<path d="M7897 5955 c-9 -8 -39 -27 -66 -41 -28 -14 -51 -29 -51 -35 0 -5 2
-9 5 -9 12 0 104 50 110 60 3 5 15 10 26 10 12 0 19 5 17 12 -7 19 -24 20 -41
3z"/>
<path d="M5412 5778 c-39 -19 -65 -73 -60 -123 8 -67 54 -105 128 -105 34 0
48 6 76 34 54 54 46 130 -19 189 -23 19 -89 22 -125 5z"/>
<path d="M3110 5019 c-25 -16 -54 -37 -65 -45 -11 -8 -27 -19 -35 -24 -13 -9
-13 -11 -1 -19 20 -13 43 1 110 65 62 60 59 69 -9 23z"/>
<path d="M5430 4904 c-42 -15 -63 -31 -76 -56 -40 -76 21 -158 118 -158 57 0
89 18 112 62 17 31 18 41 8 76 -7 22 -23 47 -37 56 -26 17 -100 29 -125 20z"/>
<path d="M2860 4880 c13 -11 29 -20 37 -20 7 0 13 -4 13 -8 0 -4 13 -16 30
-25 29 -18 110 -21 110 -5 0 4 -4 8 -9 8 -5 0 -16 6 -23 13 -42 41 -78 57
-129 57 l-52 0 23 -20z"/>
<path d="M3078 4756 c-21 -12 -41 -25 -45 -29 -10 -11 90 33 102 44 18 17 -20
7 -57 -15z"/>
<path d="M5121 4676 c-28 -30 -22 -44 49 -110 60 -56 121 -85 213 -101 91 -15
233 1 301 35 56 28 166 125 166 146 0 22 -38 54 -63 54 -15 0 -35 -14 -55 -40
-21 -25 -56 -51 -99 -71 -135 -64 -300 -38 -403 64 -55 53 -77 58 -109 23z"/>
<path d="M3732 4646 c-40 -24 -78 -48 -84 -54 -8 -8 -1 -27 21 -67 18 -32 30
-62 28 -69 -3 -6 -28 -24 -56 -39 -46 -26 -54 -27 -85 -16 -32 11 -38 10 -75
-14 -23 -15 -41 -31 -41 -35 0 -4 23 -45 51 -92 28 -47 55 -95 60 -107 15 -40
41 -45 85 -16 48 33 52 38 46 68 -3 20 4 28 49 54 30 17 59 31 66 31 6 0 25
-25 42 -55 17 -30 36 -55 42 -55 6 0 46 19 90 42 90 47 96 61 54 122 -14 20
-25 40 -25 44 0 4 -15 30 -34 57 -18 28 -52 86 -75 130 -41 77 -67 115 -80
115 -3 0 -39 -20 -79 -44z m132 -69 c25 -41 46 -76 46 -79 0 -3 25 -47 56 -98
30 -51 53 -99 50 -106 -7 -18 -95 -74 -117 -74 -18 0 -32 14 -42 42 -4 12 3
26 20 42 16 15 24 30 20 40 -12 30 -24 25 -179 -66 -60 -34 -68 -43 -68 -68 0
-22 -7 -33 -29 -44 -26 -14 -30 -14 -50 8 -11 12 -21 27 -21 32 0 5 -16 34
-35 64 -42 65 -43 82 -10 100 20 11 30 11 48 1 24 -13 23 -13 194 84 52 30 67
57 38 69 -8 3 -27 0 -40 -6 -30 -14 -50 1 -60 43 -6 23 -1 29 32 50 88 54 93
53 147 -34z"/>
<path d="M5008 4539 c-29 -17 -21 -54 25 -108 64 -77 136 -125 235 -157 78
-26 100 -29 217 -29 118 1 137 3 202 29 80 30 177 90 208 127 71 85 82 119 46
139 -33 17 -60 11 -84 -17 -80 -96 -138 -136 -241 -165 -86 -24 -233 -21 -311
6 -54 19 -148 84 -180 125 -49 62 -77 74 -117 50z"/>
<path d="M7094 4482 c-6 -4 -29 -34 -50 -67 -22 -34 -58 -78 -81 -99 -77 -69
-89 -190 -25 -259 27 -28 76 -47 125 -47 28 0 36 -17 15 -38 -15 -15 17 -51
79 -90 24 -15 43 -30 43 -34 0 -5 10 -8 23 -8 15 0 34 17 60 52 20 29 37 57
37 63 0 7 18 26 39 43 59 47 91 112 91 184 0 55 -3 62 -39 101 -37 39 -43 42
-98 44 -58 2 -58 2 -55 31 2 25 -4 36 -40 65 -36 29 -99 67 -110 67 -2 0 -8
-4 -14 -8z m61 -41 c36 -22 75 -59 75 -70 0 -4 -10 -23 -23 -42 -19 -29 -30
-35 -71 -39 -38 -4 -53 -11 -69 -32 -17 -22 -18 -30 -7 -43 19 -22 30 -19 109
31 91 58 136 70 191 48 112 -45 84 -220 -46 -290 -24 -13 -34 -24 -30 -36 6
-24 -35 -82 -60 -86 -24 -3 -114 63 -114 84 0 8 12 31 26 50 19 24 34 34 54
34 16 0 42 12 59 26 51 43 34 96 -17 55 -50 -39 -127 -79 -164 -86 -28 -5 -48
-2 -82 15 -85 41 -97 143 -25 224 16 19 45 41 64 50 30 14 35 22 33 46 -1 19
6 38 22 55 28 30 36 31 75 6z m-27 -188 c-2 -10 -14 -20 -28 -22 -25 -3 -25
-3 -6 18 23 26 39 27 34 4z"/>
<path d="M4860 4381 c-19 -37 -10 -58 53 -118 87 -84 169 -135 268 -165 332
-103 662 -37 866 173 54 55 63 80 39 112 -25 33 -68 21 -121 -34 -100 -102
-221 -165 -372 -194 -92 -18 -170 -19 -227 -4 -182 47 -263 87 -362 181 -57
54 -78 68 -102 68 -21 0 -35 -7 -42 -19z"/>
<path d="M4026 4220 c-28 -30 -37 -47 -34 -65 4 -19 -10 -39 -68 -100 -44 -47
-80 -76 -91 -75 -10 1 -23 2 -29 1 -16 -1 -74 -62 -74 -77 0 -14 285 -299 300
-299 11 0 130 114 130 126 0 4 -11 21 -25 38 l-26 30 32 35 c36 41 49 44 74
16 10 -11 25 -20 34 -20 21 0 121 101 121 122 0 14 -277 299 -298 306 -4 1
-25 -16 -46 -38z m189 -129 c69 -68 125 -127 125 -131 0 -14 -72 -90 -86 -90
-7 0 -28 13 -46 29 -31 28 -32 30 -18 56 14 26 13 28 -18 56 -28 25 -35 27
-52 17 -34 -21 -34 -45 -1 -85 36 -43 37 -50 14 -75 -23 -26 -45 -22 -78 12
-36 37 -44 37 -80 0 l-29 -30 21 -28 c17 -22 28 -27 58 -25 43 3 95 -28 95
-56 0 -9 -20 -37 -45 -61 l-45 -44 -26 25 c-65 59 -191 187 -217 218 l-27 34
22 24 c12 12 33 23 47 23 35 0 207 170 197 195 -8 22 24 66 46 62 10 -1 74
-58 143 -126z m-55 -111 c9 -18 8 -20 -15 -20 -24 0 -34 18 -18 33 11 12 22 8
33 -13z m-125 -140 c0 -29 -32 -41 -41 -16 -9 24 4 48 23 44 12 -2 18 -12 18
-28z"/>
<path d="M6750 4048 c-30 -21 -63 -45 -72 -53 -9 -8 -30 -21 -46 -29 -50 -26
-54 -37 -32 -99 11 -31 20 -61 20 -67 0 -20 -27 -9 -70 30 -24 22 -48 40 -53
40 -12 0 -118 -82 -113 -87 3 -3 25 9 50 26 24 17 54 31 65 31 19 0 54 -26
125 -92 55 -52 58 -38 17 81 -16 46 -27 89 -24 97 7 18 165 124 186 124 22 0
48 -39 42 -64 -3 -12 3 -34 15 -51 77 -115 117 -170 133 -177 9 -4 25 -12 35
-17 9 -4 18 -21 20 -38 3 -26 -4 -34 -59 -71 -34 -23 -70 -42 -79 -42 -26 0
-53 35 -45 60 5 14 -3 36 -25 70 -69 108 -79 81 -24 -66 19 -53 33 -102 30
-109 -8 -23 -95 -77 -114 -70 -9 3 -37 24 -62 46 -53 48 -118 94 -125 87 -3
-2 11 -29 30 -59 25 -37 42 -54 57 -54 12 0 31 -13 42 -29 l21 -28 -33 -28
c-18 -15 -49 -36 -67 -46 -33 -17 -35 -17 -54 1 -13 10 -21 31 -21 49 -1 42
-132 236 -160 236 -25 0 -54 43 -46 68 4 10 18 25 31 32 14 7 25 17 25 22 0
14 -10 9 -56 -24 -48 -34 -49 -39 -11 -96 13 -19 34 -35 49 -39 20 -4 37 -21
59 -57 18 -28 43 -64 56 -80 17 -20 23 -37 20 -55 -6 -28 34 -105 58 -114 12
-5 120 63 188 119 15 13 43 31 62 40 19 8 47 28 62 42 16 15 40 33 54 40 47
25 162 107 172 123 14 22 -44 106 -76 111 -32 4 -127 150 -127 196 0 33 -37
95 -63 105 -7 2 -37 -13 -67 -35z"/>
<path d="M4375 3875 c-18 -30 -24 -51 -20 -66 5 -14 -2 -38 -21 -73 -15 -28
-30 -53 -33 -56 -3 -3 -13 -18 -21 -34 -8 -16 -27 -34 -41 -40 -36 -13 -85
-92 -71 -112 4 -6 52 -37 107 -69 55 -33 107 -64 116 -70 48 -30 100 -55 113
-55 8 0 35 37 60 83 l45 82 -25 23 c-28 25 -45 23 -24 -3 9 -11 9 -22 1 -47
-17 -47 -54 -98 -72 -98 -18 0 -220 117 -266 155 l-33 27 21 29 c11 16 27 29
34 29 27 0 150 209 142 240 -5 19 27 81 40 79 5 0 26 -11 48 -24 22 -13 50
-28 62 -34 35 -16 46 -46 28 -75 -9 -13 -24 -26 -33 -28 -18 -5 -124 -173
-137 -216 -5 -17 -2 -27 12 -37 27 -21 43 -18 65 11 16 20 26 24 47 20 l26 -6
-24 20 c-30 26 -33 26 -59 -10 -26 -35 -42 -39 -42 -10 0 28 106 190 125 190
17 0 65 71 65 98 0 11 -14 26 -32 35 -18 10 -46 26 -63 37 -48 31 -87 50 -101
50 -7 0 -25 -20 -39 -45z"/>
<path d="M4787 3696 c-8 -6 -20 -30 -27 -53 -11 -35 -11 -45 1 -59 14 -17 14
-70 0 -88 -4 -6 -14 -40 -22 -75 -12 -54 -18 -66 -40 -74 -18 -7 -29 -22 -38
-53 -7 -24 -11 -50 -8 -58 7 -18 321 -117 379 -120 32 -1 37 7 68 111 15 49 3
73 -35 73 -25 0 -38 29 -24 51 5 8 9 27 9 43 0 28 1 28 44 24 24 -3 49 -2 55
2 6 3 20 39 31 79 22 79 17 101 -24 101 -20 -1 -20 -1 -3 -15 18 -13 18 -16 3
-70 -9 -31 -23 -58 -31 -61 -24 -10 -85 16 -85 35 0 39 -68 72 -94 46 -21 -21
-21 -65 -1 -65 8 0 15 9 15 20 0 13 7 20 19 20 26 0 36 -17 20 -36 -13 -15 -5
-26 25 -37 6 -2 6 -16 0 -38 -9 -29 -14 -34 -39 -34 -17 1 -42 4 -57 8 -25 7
-29 4 -43 -30 -15 -37 -14 -63 3 -63 5 0 12 14 16 31 5 25 10 30 28 27 31 -4
30 -39 -2 -55 l-25 -13 33 0 c22 0 32 5 32 15 0 18 48 20 85 3 22 -10 25 -15
19 -47 -10 -55 -22 -81 -40 -88 -16 -6 -238 53 -316 84 -21 9 -38 21 -38 28 0
28 20 65 35 65 8 0 15 7 15 15 0 8 5 15 10 15 6 0 10 13 10 28 0 15 4 32 9 37
4 6 14 33 20 60 7 28 18 63 23 78 9 23 8 30 -7 41 -14 10 -16 18 -8 42 11 31
36 36 96 20 16 -5 27 -4 27 1 0 17 -108 42 -123 29z"/>
<path d="M4933 3653 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M6050 3651 c63 -6 141 -40 167 -73 44 -57 70 -216 45 -279 -38 -97
-191 -164 -295 -130 -110 37 -183 197 -147 326 14 50 85 125 119 125 10 0 24
7 31 15 24 29 -20 17 -76 -20 -34 -22 -64 -54 -81 -83 -24 -41 -28 -57 -27
-122 3 -159 108 -280 245 -280 66 0 151 32 190 71 67 67 74 84 73 174 -1 98
-30 171 -93 230 -50 48 -66 53 -151 52 l-65 0 65 -6z"/>
<path d="M4990 3636 c8 -8 38 -17 65 -20 l50 -6 -50 20 c-61 25 -87 27 -65 6z"/>
<path d="M5374 3574 c-44 -23 -102 -83 -124 -129 -15 -31 -20 -62 -20 -122 0
-123 32 -181 125 -224 103 -49 238 -31 313 42 32 31 32 32 16 58 -29 45 -67
81 -86 81 -11 0 -33 -14 -51 -31 l-32 -31 43 23 44 24 29 -33 c37 -41 36 -54
-3 -84 -45 -34 -91 -48 -155 -48 -91 0 -163 43 -204 122 -21 42 -26 157 -9
204 19 49 73 106 122 129 30 14 43 25 35 28 -8 3 -27 -1 -43 -9z"/>
<path d="M5469 3579 c-20 -12 64 -27 122 -22 73 7 79 2 79 -80 0 -81 -11 -97
-64 -97 -38 0 -45 4 -67 38 -31 47 -68 54 -104 18 -22 -22 -25 -33 -25 -99 0
-59 4 -77 18 -90 29 -26 32 -20 11 21 -15 27 -18 48 -13 77 14 90 64 112 96
43 16 -34 22 -37 72 -43 90 -12 96 -4 96 120 0 92 -2 105 -19 115 -20 10 -185
10 -202 -1z"/>
<path d="M5993 3528 c-23 -11 -28 -29 -24 -98 2 -39 39 -130 54 -130 5 0 6 8
2 18 -25 59 -34 92 -35 128 0 44 17 68 43 59 24 -10 57 -102 57 -162 0 -34 4
-52 10 -48 6 3 10 37 10 74 0 57 -4 75 -26 107 -25 36 -53 64 -64 63 -3 0 -15
-5 -27 -11z"/>
<path d="M5480 3220 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>

</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
